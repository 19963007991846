import { useMemo } from 'react';
import { BUTTON_BASE_COLORED_COLOR } from '@kit/buttons/button-base-colored';
import { AnnouncementButtonStyled, AnnouncementContainerStyled, AnnouncementImageStyled, AnnouncementMessageStyled, AnnouncementStyled, AnnouncementTitleStyled } from './styled';
import { AnnouncementProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Announcement<P extends {
  children?: React.ReactNode;
  className?: string;
}>(props: AnnouncementProps<P>): React.ReactElement<P> {
  const {
    title,
    message,
    image = '/images/bg/empty-page.jpeg',
    buttonComponent,
    buttonProps
  } = props;
  const Button = useMemo(() => {
    return buttonComponent ? AnnouncementButtonStyled.withComponent(buttonComponent, process.env.NODE_ENV === "production" ? {
      target: "eg6eecj0"
    } : {
      target: "eg6eecj0",
      label: "Button"
    }) : AnnouncementButtonStyled;
  }, [buttonComponent]);
  return _jsx(AnnouncementStyled, {
    children: _jsxs(AnnouncementContainerStyled, {
      children: [_jsx(AnnouncementImageStyled, {
        src: image
      }), _jsx(AnnouncementTitleStyled, {
        children: title
      }), !message ? null : _jsx(AnnouncementMessageStyled, {
        children: message
      }), !buttonProps?.children ? null : _jsx(Button, {
        color: BUTTON_BASE_COLORED_COLOR.DARK_BLUE_BORDERS,
        ...buttonProps,
        children: buttonProps?.children
      })]
    })
  });
}